<template>
  <div>
    {{ transactionStatus }}
    <Wallet></Wallet>
  </div>
</template>
<script>
import Wallet from '@/views/components/Wallets.vue'

export default {
  components: {
    Wallet,
  },
  data() {
    return {
      transactionStatus: '',
    }
  },
}
</script>
