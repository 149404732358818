import firebase from 'firebase/app'
import 'firebase/firestore'
import dbFunctions from '@/db'

export default {

  async mintToken(playerId, templateId, amount) {
    let coinMinted = false
    const mintToken = firebase.functions().httpsCallable('mintToken')
    await mintToken({
      playerId,
      templateId,
      amount: amount.toString(),
    })
      .then(mintedData => {
        coinMinted = mintedData.data.token
      })
      .catch(err => {
        console.log(err)
        coinMinted = false
      })

    return coinMinted
  },

  async burnToken(playerId, mintId, amount) {
    let burnt = false
    const burnToken = firebase.functions().httpsCallable('burnToken')
    await burnToken({
      playerId,
      tokenId: mintId,
      amount: amount.toString(),
    })
      .then(burnData => {
        console.log(burnData)
        console.log('NFT token successfully Burnt')
        burnt = true
      })
      .catch(err => {
        console.log(err)
        burnt = false
      })

    return burnt
  },

  async createTokenTemplate(fdata) {
    const data = fdata
    let templateId = 0
    const createTemplate = firebase.functions().httpsCallable('createTokenTemplate')
    data.props.image = `https://storage.googleapis.com/fk-public/body-${data.slug}.png`
    await createTemplate({
      name: data.name, // base template name
      cap: data.cap, // market cap (use for limited edition NFT)
      type: data.type, // NFT or FT
      card_type: data.card_type || 'base',
      props: data.props,
    })
      .then(templateData => {
        const template = templateData.data.template.id
        const fields = {
          token_template_id: template,
          card_type: data.card_type,
        }
        dbFunctions.updateFighter(data.slug, fields)
        templateId = template

        return templateId
      })
      .catch(err => {
        console.log(err)
        templateId = 0
      })

    return templateId
  },
}
