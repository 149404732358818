<template>
  <div>
    <v-container>
      <v-row>
        <v-col class="col-md-8 offset-md-2 col-sm-12">
          <div
            v-if="!dialog"
            class="text-center d-all"
          >
            <v-card class="text-center d-all">
              <v-card-title>
                <h3 class="mx-auto">
                  Connect Dapper Account
                </h3>
              </v-card-title>
              <p
                v-if="!dapperUser.addr"
                class="text-center"
              >
                Connect your Dapper Account to activate Strike PowerUps.
              </p>
              <v-card-text class="text-center mb-3 pb-4">
                <div class="mx-auto">
                  <v-btn
                    color="success"
                    class="mr-1  d-none"
                    outlined
                    dark
                    @click="dialog=true"
                  >
                    Solana Wallet
                  </v-btn>
                  <v-btn
                    v-if="!dapperUser.addr && !user.profile.dapperAddress"
                    color="success"
                    class="ml-2 mb-3"
                    outlined
                    dark
                    @click="connectFlow"
                  >
                    Connect
                  </v-btn>
                  <div
                    v-if="dapperUser.addr || user.profile.dapperAddress"
                    class="text-center"
                  >
                    <h2>Connected</h2>
                    <v-icon
                      large
                      color="success"
                      class="ma-2"
                    >
                      {{ icons.mdiCheckCircleOutline }}
                    </v-icon>
                    <br>
                    <v-chip to="/powerups">
                      Account: {{ user.profile.dapperAddress || dapperUser.addr }}
                    </v-chip>
                    <br>
                    <v-btn
                      v-if="dapperUser.addr ||user.profile.dapperAddress"
                      class="mt-2 mb-0"
                      outlined
                      dark
                      x-small
                      @click="disConnectFlow"
                    >
                      Disconnect
                    </v-btn>

                    <div class="mt-4 mb-3">
                      <h3>Strike PowerUps Enabled!</h3>
                      <p>You've unlocked <a href="/leagues">Strike Leagues.</a></p>
                      <p>Use your "PowerUps" in weekly tournaments.</p>

                      <v-btn
                        v-if="returnPath"
                        color="primary"
                        outlined
                        :to="returnPath"
                      >
                        Continue
                      </v-btn>
                      <v-btn
                        v-else
                        color="primary"
                        outlined
                        to="/powerups"
                      >
                        Get Started
                      </v-btn>
                      <br>
                    </div>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog
      v-model="dialog"
      width="850"
      persistent
    >
      <v-row>
        <v-col
          cols="12"
          class="12 text-center"
        >
          <v-card>
            <v-card-text class="text-center">
              <div v-if="!transactionSent">
                <h2 class="mx-2 my-2 mb-4">
                  <v-icon>{{ icons.mdiWalletPlusOutline }}</v-icon>
                  Connect Solana Wallet
                </h2>
                <small class="mt-0 pt-0">Beta accounts should use {{ solanaNetwork }}</small>
                <br>
                <br>
                <v-btn
                  outlined
                  color="primary"
                  @click="getWallets"
                >
                  Check Installed Wallets
                </v-btn>
                <div class="ma-6">
                  <v-chip
                    large
                    class="mr-4"
                    outlined
                    :disabled="!installedWallets.phantom"
                    :class="{'primary':connectedWallets.phantom && pubKey, 'success':selectedWallet=='phantom'}"
                    @click="connectPhantom()"
                  >
                    <v-img
                      :src="require('@/assets/images/wallets/phantom.svg')"
                      max-height="40px"
                      alt="Phantom Wallet"
                      contain
                      class="me-3 ml-1"
                    ></v-img>
                    Phantom <span
                    v-if="connectedWallets.phantom && pubKey"
                    class="ml-2 text-xs"
                  >(Connected)</span>
                  </v-chip>

                  <v-chip
                    large
                    class="mr-4"
                    outlined
                    :disabled="!installedWallets.solflare"
                    :class="{'primary':connectedWallets.solflare && pubKey, 'success':selectedWallet=='solflare'}"
                    @click="connectSolflare()"
                  >
                    <v-img
                      :src="require('@/assets/images/wallets/solflare.svg')"
                      max-height="40px"
                      alt="Solflare Wallet"
                      contain
                      class="me-3 ml-1"
                    ></v-img>
                    Solflare <span
                    v-if="connectedWallets.solflare && pubKey"
                    class="ml-2 text-xs"
                  >(Connected)</span>
                  </v-chip>
                  <v-row v-if="selectedWallet">
                    <v-col
                      cols="12"
                      class="mt-6 pt-6 mb-0 pt-0 mx-auto"
                    >
                      <v-text-field
                        v-if="!fixedAmount"
                        v-model="qty"
                        class="coin-amount"
                        type="number"
                        color="success"
                        :label="buyText"
                        @change="setCoins"
                      ></v-text-field>
                      <small>≈ Exchange Rate:</small><br>
                      <small>SOL: {{ toSol|twoDecimal }}</small><br>
                      <small>USD: {{ toUSD|twoDecimal }}</small>
                    </v-col>
                    <v-col
                      cols="12"
                    >
                      <v-btn
                        :loading="transactionLoading"
                        outlined
                        large
                        color="success"
                        @click="signTransactionWallet()"
                      >
                        <span v-if="fixedAmount">Send </span> <span
                        v-if="!fixedAmount"
                      >Buy </span>
                        <span class="ml-2">{{ qty }} {{ selectedCurrency }}</span>
                      </v-btn>
                      <p
                        v-if="tooLowError"
                        class="text-warning"
                      >
                        You need to enter more than 0.
                      </p>
                    </v-col>
                  </v-row>
                </div>
              </div>

              <v-btn
                v-if="!transactionSent"
                :disabled="transactionLoading"
                color="outlined"
                outlined
                text
                small
                class="mb-6"
                @click="dialog = false"
              >
                Cancel
              </v-btn>
              <div v-if="transactionSent && !solPurchase">
                <h2 class="text-success">
                  Transaction Successfully Sent!
                </h2>
                <p>It may take a moment for you KombatCoin balance to update.</p>
                <a href="/recruit-fighters">
                  <v-btn
                    outlined
                    color="success"
                  >
                    Buy Fighters!
                  </v-btn>
                </a>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-dialog>
  </div>
</template>

<script>
/* eslint-disable prefer-destructuring */

// import {Token} from '@solana/spl-token'
import * as web3 from '@solana/web3.js'
import {mdiWalletPlusOutline, mdiCheckCircleOutline} from '@mdi/js'
import * as fcl from '@onflow/fcl'
import firebase from 'firebase/app'
import 'firebase/firestore'
import tokenFunctions from '@/tokens'
import dbFunctions from '@/db'

fcl.config({
  'discovery.wallet': 'https://accounts.meetdapper.com/fcl/authn-restricted',
  'discovery.wallet.method': 'POP/RPC',
  'accessNode.api': 'https://access-mainnet-beta.onflow.org',
  'app.detail.title': 'FantasyKombat',
  'app.detail.icon': 'https://www.fantasykombat.com/logo_small.png',
})

export default {
  props: {
    selectedCurrency: {
      type: String,
      default: 'Gold KombatCoins',
    },
    fixedAmount: {
      type: Number,
      default: 0,
    },
    solPurchase: {
      type: Boolean,
      default: false,
    },
    fighterItem: {
      type: Object,
      default: () => {
      },
    },
  },
  data() {
    return {
      disableCancel: false,
      dapperUser: '',
      ufcStrikeDeposits: [],
      ufcStrikeWithdraws: [],
      ufcStrikeMinted: [],
      ufcStrikeListed: [],
      ufcStrikeListAll: [],
      myNFTS: [],
      tooLowError: false,
      currencies: [],
      dialog: false,
      user: this.$store.state.user,
      icons: {
        mdiWalletPlusOutline,
        mdiCheckCircleOutline,
      },
      solanaNetwork: 'https://api.devnet.solana.com', // todo early beta
      FKreceiveWallet: 'BnLvbV7QQapBYbVC9UpqWVonF1MwRh4Dhd8gDyQ6bRwp',
      installedWallets: {
        phantom: '',
        solflare: '',
      },
      connectedWallets: {
        phantom: false,
        solflare: false,
      },
      transactionLoading: false,
      selectedWallet: '',
      pubKey: '',
      qty: 0,
      toSol: 0,
      toUSD: 0,
      buyText: `How many ${this.selectedCurrency} do you want to buy?`,
      transactionSent: false,
      returnPath: '',
    }
  },
  watch: {
    pubKey: {
      handler(pubKey) {
        const user = this.user
        const fields = {solanaAddress: pubKey}
        window.$crisp?.push(['set', 'session:event', [[['walletConnect', {status: 'completed'}, 'green']]]])
        dbFunctions.updateUsersProfile(user, fields).catch(err => {
          console.log(err)
        })
      },
    },
    qty: {
      handler() {
        this.getRates()
      },
    },
  },
  mounted() {
    if (this.user.profile.dapperAddress) {
      this.dapperUser = this.user.profile.dapperAddress
    }
    this.getWallets()
    this.getCurrencies()
    this.returnPath = this.$route.query?.return
  },
  methods: {
    async setCoins(e) {
      this.qty = e
    },
    getExchange(ticker) {
      const currencyList = this.currencies

      return currencyList.findIndex(element => {
        if (element.ticker === ticker) {
          return true
        }

        return false
      })
    },
    async getCurrencies() {
      await dbFunctions.getCurrencies()
        .then(currencyList => {
          this.currencies = currencyList
        })
    },
    getRates() {
      const value = this.qty

      const usd = this.currencies[this.getExchange('USD')].exchange
      const sol = this.currencies[this.getExchange('SOL')].exchange
      const GKC = this.currencies[this.getExchange('GKC')].exchange

      // convert to silver first to get exchange rates
      const silver = value / GKC

      this.toSol = (silver * usd) * sol
      this.toUSD = silver * usd
    },

    async connectFlow() {
      console.log('Connect Flow')

      // fcl.unauthenticate()

      fcl.authenticate().then(e => {
        this.dapperUser = e
        const fields = {dapperAddress: e.addr}
        window.$crisp?.push(['set', 'session:event', [[['dapperWalletConnect', {status: 'completed'}, 'green']]]])
        fields.dapperAddressCreated = firebase.firestore.FieldValue.serverTimestamp()
        dbFunctions.updateUsersProfile(this.user, fields).catch(err => {
          console.log(err)
        })
      })
    },
    async disConnectFlow() {
      const fields = {dapperAddress: ''}
      fields.dapperAddressCreated = firebase.firestore.FieldValue.serverTimestamp()
      dbFunctions.updateUsersProfile(this.user, fields).then(() => {
        this.dapperUser = ''
        this.user.profile.dapperAddress = ''
        fcl.unauthenticate()
      })
        .catch(err => {
          console.log(err)
        })
    },
    async getWallets() {
      window.$crisp?.push(['set', 'session:event', [[['walletConnect', {status: 'started'}, 'blue']]]])
      if (this.fixedAmount) {
        this.qty = this.fixedAmount
      }
      if (window.solana.isPhantom) {
        this.installedWallets.phantom = window.solana
        this.installedWallets.phantom.connect({onlyIfTrusted: true})
      }
      this.installedWallets.solflare = window.solflare
      if (this.installedWallets.solflare.isSolflare) {
        console.log(this.installedWallets.solflare)
      }
    },
    async connectPhantom() {
      if (this.installedWallets.phantom) {
        console.log('Connect Phantom')
        const resp = await window.solana.connect()
        this.pubKey = resp.publicKey.toString()
        this.connectedWallets.phantom = true
        this.selectedWallet = 'phantom'
      }
    },
    async connectSolflare() {
      if (this.installedWallets.solflare) {
        console.log('Connect solflare')
        console.log(this.installedWallets.solflare)
        this.installedWallets.solflare.connect()
          .then(() => {
            this.pubKey = this.installedWallets.solflare.publicKey.toString()
            console.log(this.pubKey)
            this.connectedWallets.solflare = true
            this.selectedWallet = 'solflare'
          })
          .catch(err => {
            console.log(err)
          })
      }
    },
    async signTransactionWallet() {
      let startTransaction = false
      let min = 0.1
      if (this.selectedCurrency === 'Gold KombatCoins') {
        min = 1
      }
      if (this.qty < min) {
        this.tooLowError = true
      } else {
        this.tooLowError = false
        startTransaction = true
      }
      if (startTransaction) {
        const fromWallet = new web3.PublicKey(this.pubKey)
        const toWallet = new web3.PublicKey(this.FKreceiveWallet)
        const transaction = new web3.Transaction()

        // todo early beta setting change to custom SPL token and use main GoldKombatCoin account (in .env)
        transaction.add(
          web3.SystemProgram.transfer({
            fromPubkey: fromWallet,
            toPubkey: toWallet,
            lamports: web3.LAMPORTS_PER_SOL * this.toSol, // 1 SOL =  Lamport = 10^-9 SO
          }),
        )
        const network = this.solanaNetwork
        const connection = new web3.Connection(network)

        // dev airdrop
        /*
        const airdropSignature = await connection.requestAirdrop(
          toWallet,
          web3.LAMPORTS_PER_SOL,
        )
        await connection.confirmTransaction(airdropSignature)
        */
        const {blockhash} = await connection.getRecentBlockhash()
        transaction.recentBlockhash = blockhash
        transaction.feePayer = fromWallet
        const signedTransaction = await this.installedWallets[this.selectedWallet].signTransaction(transaction)
        this.transactionLoading = true
        connection.sendRawTransaction(signedTransaction.serialize())
          .then(resp => {
            console.log(`listen to ${resp}`)
            console.log(this.selectedCurrency)
            if (this.selectedCurrency === 'Gold KombatCoins') {
              console.log('mint gold coins')
              tokenFunctions.mintToken(this.user.profile.starDustPlayerId, process.env.VUE_APP_GOLD_KOMBATCOIN_TEMPLATE, this.qty)
                .then(() => {
                  console.log('Mint Successful')
                  dbFunctions.readUsersProfile(this.user.uid)
                    .then(profileData => {
                      const userProfile = profileData
                      const newQty = parseInt(userProfile.goldKombatCoins, 10) + parseInt(this.qty, 10)
                      const fields = {goldKombatCoins: newQty, solanaAddress: this.pubKey}
                      dbFunctions.updateUsersProfile(this.user, fields)
                      this.transactionSent = true
                      console.log('Save transaction in profile')
                      this.$emit('transactionStatus', 'complete')
                      this.transactionLoading = false
                      window.$crisp?.push(['set', 'session:event', [[['Purchase', {
                        type: this.selectedCurrency,
                        amount: newQty,
                      }, 'green']]]])
                      this.$forceUpdate()
                    })
                    .catch(err => {
                      this.$emit('transactionStatus', 'failed')
                      this.transactionLoading = false
                      console.log(err)
                      this.$parent.closeWallet()
                    })
                })
                .catch(err => {
                  this.$emit('transactionStatus', 'failed')
                  this.transactionLoading = false
                  console.log(err)
                  window.$crisp?.push(['set', 'session:event', [[['walletConnect', {status: 'failed'}, 'red']]]])
                  this.$parent.closeWallet()
                })
            } else {
              console.log('no mint gold coins')
              this.transactionSent = true
              this.transactionLoading = false
              if (this.solPurchase) {
                window.$crisp?.push(['set', 'session:event', [[['Purchase', {
                  type: 'solPurchase',
                  amount: this.qty,
                }, 'green']]]])
                console.log('complete purchase')
                this.dialog = false
                this.$parent.solApproved()
              }
            }
          })
          .catch(err => {
            console.log(err)
            this.$parent.closeWallet()
          })
      }
    },
  },
}

</script>

<style lang="scss" scoped>
.v-input.coin-amount {
  width: 333px;
  margin: auto;
}

.v-input.coin-amount input {
  margin: auto;
  display: inline;
  font-size: 20px;
  text-align: center;
  margin-top: 10px;
}
</style>
